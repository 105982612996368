// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 200px 80px;
  }
  
  .spinner span {
    font-size: 2rem;
    animation: fade 1s linear 0s infinite;
    padding-right: 1rem;
  }
  
  .half-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #bfa671;
    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.5s linear 0s infinite;
  }
  
  
  @keyframes bigger {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(2);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }`, "",{"version":3,"sources":["webpack://./src/CSS/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,qCAAqC;IACrC,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,iCAAiC;IACjC,kBAAkB;IAClB,uCAAuC;EACzC;;;EAGA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE;MACE,oBAAoB;IACtB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF","sourcesContent":[".spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: transparent;\n    padding: 200px 80px;\n  }\n  \n  .spinner span {\n    font-size: 2rem;\n    animation: fade 1s linear 0s infinite;\n    padding-right: 1rem;\n  }\n  \n  .half-spinner {\n    width: 50px;\n    height: 50px;\n    border: 3px solid #bfa671;\n    border-top: 3px solid transparent;\n    border-radius: 50%;\n    animation: spin 0.5s linear 0s infinite;\n  }\n  \n  \n  @keyframes bigger {\n    from {\n      transform: scale(0);\n    }\n    to {\n      transform: scale(2);\n    }\n  }\n  \n  @keyframes spin {\n    from {\n      transform: rotate(0);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n  @keyframes fade {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
